import React, { Component } from "react";
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {
  state = {
    services: [
      {
        title: "Mobile App development",
        icon: "pe-7s-phone",
        description:
          "We develop Mobile apps that runs both on Android and iOS with buttery smooth UI and highly reliable performance",
      },
      {
        title: "Web App development",
        icon: "pe-7s-global",
        description:
          "We develop highly responsive Web app's with innovative design and layout that runs on all the device browsers",
      },
      {
        title: "IT Support",
        icon: "pe-7s-tools",
        description:
          "We support installing and maintaining IT solutions, providing assistance with technology-related issues and troubleshooting specific problems",
      },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="services">
          <Container>
            {/* Render section title */}
            <SectionTitle
              title="Our Services"
              description="We provide IT services to small businesses and new enterprises in a reliable and cost effective way"
            />

            <Row className="mt-5">
              {/* render service box */}
              {this.state.services.map((service, key) => (
                <ServiceBox
                  key={key}
                  title={service.title}
                  icon={service.icon}
                  description={service.description}
                />
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
