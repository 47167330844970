import Index2 from "./pages/Index2/Index2";
//  import Index1 from "./pages/Index1/Index1";
// import Index3 from "./pages/Index3/Index3";
// import Index4 from "./pages/Index4/Index4";
// import Index5 from "./pages/Index5/Index5";
// import Index6 from "./pages/Index6/Index6";
// import Index7 from "./pages/Index7/Index7";
// import Index8 from "./pages/Index8/Index8";
// import Index9 from "./pages/Index9/Index9";
// import Login from "./pages/Auth/login";
// import SignUp from "./pages/Auth/signup";
// import PasswordForget from "./pages/Auth/password_forget";

const routes = [
  //Auth
  // { path: "/signup", component: SignUp },
  // { path: "/login", component: Login },
  // { path: "/password_forget", component: PasswordForget },

  // { path: "/index9", component: Index9 },
  // { path: "/index8", component: Index8 },
  // { path: "/index7", component: Index7 },
  // { path: "/index6", component: Index6 },
  // { path: "/index5", component: Index5 },
  // { path: "/index4", component: Index4 },
  // { path: "/index3", component: Index3 },
  // { path: "/index2", component: Index2 },
  { path: "/", component: Index2 },
];

export default routes;
