import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class Clients extends Component {
  state = {
    clients: [
      {
        id: 1,
        img: "assets/images/clients/vcart.png",
        link: "https://zentpeople.com/assets/files/vcart/vcart.apk",
      },
      {
        id: 2,
        img: "assets/images/clients/buysocial.png",
        link: "https://trybuysocial.com",
      },
      {
        id: 3,
        img: "assets/images/clients/spacescan.png",
        link: "https://play.google.com/store/apps/details?id=com.spacescan.sentinel",
      },
      { id: 4, img: "assets/images/clients/linkaro.png", link: undefined },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <section className="section-sm bg-light">
          <Container>
            <Row>
              {this.state.clients.map((client, key) => (
                <Col md="3" key={key}>
                  <div className="client-images my-3 my-md-0">
                    <a href={client.link} target="_blank" rel="noreferrer">
                      <img
                        src={client.img}
                        alt="logo-img"
                        className="mx-auto img-fluid d-block"
                        id="client-logo"
                      />
                    </a>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Clients;
